body {
  padding: 20px;
}

.App {
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

img {
  max-width: 80vw;
  margin: 20px auto 30px;
}
